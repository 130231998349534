(function () {

  angular.module('kmi.lms.bms.user.learning')
    .directive('badgesSummaryTile', BadgesSummaryTile);

  function BadgesSummaryTile() {
    return {
      restrict: 'AE',
      scope: {
        badgesCount: '=?'
      },
      template: require('ajs/custom_modules/bms/user/learning/summary/badges-summary-tile.html').default,
      controller: BadgesSummaryTileController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function BadgesSummaryTileController($q, _, currentUser, UserAchievement) {
    var vm = this, cacheKey;
    var userId = currentUser.get().id;

    vm.$onInit = onInit;

    function onInit() {
      vm.loading = true;
      vm.statistic = {
        completed: 0
      };

      cacheKey = ['badgesSummary:', userId].join('');

      bindCachedData();
      loadData();
    }

    function bindCachedData() {
      var cachedModel = JSON.parse(sessionStorage.getItem(cacheKey));

      if (cachedModel) {
        vm.statistic = cachedModel.statistic;
        vm.loading = false;
      }
    }

    function loadData() {
      $q.all([
        UserAchievement.stat({userId: userId}).$promise
          .then(function (data) {
            vm.userAchievementsCount = data.count;
          }),
        UserAchievement.stat_available({userId: userId}).$promise
          .then(function (data) {
            vm.availableAchievementsCount = data.count;
          })
      ]).then(function () {
        vm.loading = false;
        calculateStat();

        sessionStorage.setItem(cacheKey, JSON.stringify({
          statistic: vm.statistic
        }));
      });
    }

    function calculateStat() {
      vm.statistic.total = Math.max(vm.userAchievementsCount, vm.availableAchievementsCount);
      vm.statistic.completed = vm.userAchievementsCount;
      vm.statistic.percentage = vm.statistic.total ? vm.statistic.completed / vm.statistic.total * 100 : 0;

      vm.badgesCount = vm.statistic.total;
    }
  }
})();
