(function (){

  angular
    .module('kmi.lms.bms.user')
    .component('bmsUserAccountDetails', {
      template: require('ajs/custom_modules/bms/user/registration/components/account-details.html').default,
      controller: BmsUserAccountDetailsController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formOptions: '=?'
      }
    });

  /* @ngInject */
  function BmsUserAccountDetailsController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {}
  }
})();
