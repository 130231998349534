(function () {

  angular.module('elmsSpaApp')
    .component('bmsCommonWealthBanner', {
      template: require('ajs/custom_modules/bms/login/common-wealth-banner.html').default,
      controller: BmsCommonWealthBannerController,
      controllerAs: 'vm',
      bindings: {
        parentId: '@'
      }
    });

  function BmsCommonWealthBannerController(){
    const vm = this;

    vm.$onInit = onInit;

    function onInit(){

    }
  }
})();
