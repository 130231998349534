(function () {

  angular
    .module('kmi.lms.bms.user')
    .component('bmsUserSelfRegistration', {
      template: require('ajs/custom_modules/bms/user/registration/user-registration.html').default,
      controller: BmsUserSelfRegistrationController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function BmsUserSelfRegistrationController(
    $scope, User, elmsAuthService, notificationService, urlVersionService,
    backUrlService, navigationService, userTimeZonesService) {
    var vm = this;

    vm.formOptions = {submitted: false, timeZone: false, phone: false};
    vm.backUrl = backUrlService;
    vm.validationPending = null;


    vm.$onInit = onInit;
    vm.submit = submit;

    function onInit() {
      vm.user = new User({
        loginName: '',
        password: '',
        firstName: '',
        lastName: '',
        email: '',
        policiesSignOff: false,
        receiveEmails: true,
        stayInformed: false,
        annualNotification: false,
        timeZoneId: null,
        attributes: {}
      });

      userTimeZonesService.populateDefaultTimeZone(vm.user);
    }

    function submit() {
      vm.formOptions.submitted = true;

      if (vm.userForm.$valid) {
        var userDraft = angular.copy(vm.user);
        $scope.$broadcast('event:customAttributes.saving', userDraft);

        vm.validationPending = userDraft.save();
        vm.validationPending
          .then(function () {
            urlVersionService.update();

            $scope.$on('elmsAuthService.notification.login', function () {
              navigationService.transitionToTargetState();
            });

            elmsAuthService.login({
              username: vm.user.loginName,
              password: vm.user.password
            });
          },
          function () {
            vm.validationPending = null;
            notificationService.error('An error occurred during registration. Please try again later.', 5e3);
          });

      } else {
        vm.validationPending = null;
        notificationService.error('Please fill in all required fields', 2e3);
      }
    }
  }
})();
