(function () {

  angular.module('kmi.lms.user.lsCompilations')
    .component('userLsCompilations', {
      template: require('ajs/custom_modules/bms/user/ls-compilations/user-ls-compilations.html').default,
      controller: UserLsCompilationsController,
      controllerAs: 'vm',
      bindings: {
        userId: '=',
        actual: '=?',
        showStatistics: '=',
        statusSet: '=',
        title: '=viewTitle',
        columns: '=?',
        labels: '=',
        formatId: '='
      }
    });

  /* @ngInject */
  function UserLsCompilationsController($scope, userLsCompilationsService) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.progress = 0;
      vm.title = vm.title || 'Collections';

      activate();
    }

    function activate() {
      loadData();

      // Reload courses from WS when some course registration data changed
      $scope.$on('event:course.registration.action.finished', function () {
        loadData();
      });

      $scope.$on('event:course.updateData', function () {
        loadData();
      });

      $scope.$on('user:courses:export', exportLsCompilations);
    }

    function loadData() {
      vm.loadingPromise = userLsCompilationsService.getLsCompilationsForest(vm.userId, {labels: vm.labels}, vm.formatId)
        .then(function (response) {
          vm.courses = response.courses;
          vm.progress = response.stats.completed;
        }).finally(function () {
          vm.loadingPromise = null;
        });
    }

    function exportLsCompilations() {
      userLsCompilationsService.exportLsCompilations(vm.userId, {labels: vm.labels}, vm.formatId);
    }
  }
})();
