(function (){

  angular
    .module('kmi.lms.bms.user')
    .component('bmsPortalSelection', {
      template: require('ajs/custom_modules/bms/user/edit/components/portal-selection.html').default,
      controller: BmsPortalSelectionController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formOptions: '=?',
        portalsEnabled: '=?',
        viewMode:'<?'
      }
    });

  /* @ngInject */
  function BmsPortalSelectionController(groupService, Group, _, globalConfig) {
    var vm = this;

    vm.formOptions = {submitted: false};
    vm.selectedGroupCategories = {};
    vm.groupsByCategories = {};
    vm.portalsEnabled = false;
    vm.settings = globalConfig.settings;

    vm.$onInit = onInit;
    vm.selectionChange = bindSelectedGroupsToUser;
    vm.multiChoiceGroupChecked = multiChoiceGroupChecked;

    function onInit() {
      bindSelfRegGroups();
    }

    function bindSelfRegGroups() {
      groupService.getDefaultSelfRegGroups()
        .then(function (dfGroups) {
          if (dfGroups.length) {
            vm.portalGroup = dfGroups[0].group;

            Group.query({
              query: {
                parent: vm.portalGroup.id,
                selfReg: true
              }
            }).$promise
              .then(function(groups) {
                populateGroupSelection(groups);
                bindSelectedGroups(groups);
              });
          }
        });
    }

    function bindSelectedGroups(groups) {
      var assignedGroupIds = _.map(vm.user.groups, 'id');

      _.forEach(groups.items, function(g) {
        if (_.includes(assignedGroupIds, g.id)) {
          if (vm.groupsByCategories[g.categoryId].multipleChoice) {
            if (!(g.categoryId in vm.selectedGroupCategories)) {
              vm.selectedGroupCategories[g.categoryId] = [];
            }
            vm.selectedGroupCategories[g.categoryId].push(g.id);
          } else {
            vm.selectedGroupCategories[g.categoryId] = g.id;
          }
          g.selected = true;
        }
      });
    }

    function populateGroupSelection(groups) {
      vm.groupsByCategories = _.reduce(_.sortBy(groups.items, 'name'), function (result, group) {
        result[group.categoryId] = result[group.categoryId] || {name: group.category.name, groups: []};
        result[group.categoryId].groups.push(group);
        if (vm.settings.user.multipleChoiceGroupCategories && !result[group.categoryId].multipleChoice) {
          result[group.categoryId].multipleChoice =
            vm.settings.user.multipleChoiceGroupCategories.indexOf(group.category.name.toLowerCase()) > -1;
        }
        return result;
      }, {});

      vm.portalsEnabled = !!Object.keys(vm.groupsByCategories).length;
    }

    function bindSelectedGroupsToUser() {
      var selectedGroups = [];
      for (var categoryId in vm.selectedGroupCategories) {
        var selectedCategoryGroups = vm.selectedGroupCategories[categoryId];
        var categoryGroups = _.get(vm.groupsByCategories[categoryId], 'groups');
        if (angular.isArray(selectedCategoryGroups)) {
          for (var i = selectedCategoryGroups.length-1; i >= 0; i--) {
            selectedGroups.push(_.find(categoryGroups, {id: selectedCategoryGroups[i]}));
          }
        } else {
          selectedGroups.push(_.find(categoryGroups, {id: selectedCategoryGroups}));
        }
      }
      vm.user.groups = selectedGroups;
    }

    function multiChoiceGroupChecked(item) {
      item.selected = !item.selected;

      if (item.selected) {
        if (!vm.selectedGroupCategories[item.categoryId]) {
          vm.selectedGroupCategories[item.categoryId] = [];
        }
        vm.selectedGroupCategories[item.categoryId].push(item.id);
      } else {
        _.remove(vm.selectedGroupCategories[item.categoryId], function(groupId){
          return groupId === item.id;
        });
        if (!vm.selectedGroupCategories[item.categoryId].length) {
          vm.selectedGroupCategories[item.categoryId] = null;
        }
      }
      bindSelectedGroupsToUser();
    }

  }
})();
