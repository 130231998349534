(function () {

  angular.module('kmi.lms.user')
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider, $provide) {
    $provide.decorator('editAccountDirective', function ($delegate) {
      var directive = $delegate[0];

      directive.template = require('ajs/custom_modules/bms/user/edit/account.html').default;

      return $delegate;
    });

    $stateProvider
      .state('edit.account', {
        url: '/account?activeView',
        component: 'editUserAccount',
        params: {
          activeView: null
        },
        data: {
          label: 'Account edit',
          availableFor: ['regularUser', 'admin']
        },
        resolve: {
          user: function ($q, currentUser, User, customUserAttributesService) {
            var defer = $q.defer();

            User.get().then(
              function (user) {
                var updatedUser = customUserAttributesService.loadCustomAttributes(user);
                if (currentUser.get().bmsUserId) {
                  defer.reject(null);
                } else {
                  currentUser.set(updatedUser);
                  defer.resolve(updatedUser);
                }
              });

            return defer.promise;
          }
        }
      });
  }
})();
