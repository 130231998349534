(function () {

  angular.module('kmi.lms.course.common')
    .config(moduleConfigCommon);

  function moduleConfigCommon($provide) {
    $provide.decorator('collectionTerminalPrerequisiteStatuses', function ($delegate) {
      $delegate = [5,40,41];

      return $delegate;
    });
  }
})();
