(function () {

  angular.module('kmi.lms.bms.user')
    .component('bmsUserShortInfo', {
      template: require('ajs/custom_modules/bms/user/view/user-short-info.html').default,
      controller: BmsUserShortInfoController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function BmsUserShortInfoController($scope, $uibModal, currentUser, userGroupsService, bmsUserService, globalConfig, $q) {
    var vm = this;

    vm.agency = '';
    vm.userSettings = currentUser.get();
    vm.showQrCode = showQrCode;
    vm.appSettings = globalConfig.settings;


    activate();

    function activate() {
      $q.all([
        bmsUserService.getBmsUser(vm.userSettings.id)
          .then(function (bmsUser) {
            var curUser = currentUser.get();
            curUser.bmsUserId = bmsUser.bmsUserName;

            currentUser.set(curUser);
          }),
        userGroupsService.getTopLevelGroups(null, 140)
          .then(function (agency) {
            if (agency && agency.length) {
              vm.agency = agency[0].name;
            }
          })
      ])
        .then(function () {
          $scope.$broadcast('event:userAgencyLoaded');
        });
    }

    function showQrCode() {
      var modalInstance = $uibModal.open({
        component: 'bmsUserQRCodeDialog',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          user: function () {
            return currentUser.get();
          }
        }
      });

      return modalInstance.result;
    }

  }


})();
