(function () {

  angular.module('kmi.lms.user.lsCompilations')
    .factory('userLsCompilationsService', userLsCompilationsService);

  /* @ngInject */
  function userLsCompilationsService($http, $window, $httpParamSerializer, moment) {
    return {
      getLsCompilationsForest: getLsCompilationsForest,
      exportLsCompilations: exportLsCompilations
    };

    function getLsCompilationsForest(userId, attrs, formatId) {
      var elements = ['/a/user/', userId, '/ls_compilations/'];
      if (formatId){
        elements.push(formatId);
        elements.push('/');
      }

      return $http.get(elements.join(''), {params: attrs})
        .then(function (response) {
          return response.data;
        });
    }

    function exportLsCompilations(userId, attrs, formatId) {
      var elements = ['/a/user/', userId, '/ls_compilations/'];
      if (formatId){
        elements.push(formatId);
        elements.push('/');
      }

      var fileName = formatId === 117 ? 'certifications_' : 'collections_' + moment(new Date()).format('YYYY-MM-DD');
      var params = angular.extend({format: 'csv', file_name: fileName}, attrs);

      elements.push('?');
      elements.push($httpParamSerializer(params));

      $window.location.assign(elements.join(''));
    }
  }

})();



