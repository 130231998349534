(function () {

  angular
    .module('kmi.lms.bms.user.learning')
    .component('userCourseStatusFilterSelector', {
      template: require('ajs/custom_modules/bms/user/learning/components/status-filter-selector.html').default,
      controller: UserCourseStatusFilterSelectorController,
      controllerAs: 'vm',
      bindings: {
        statusFilter: '=',
        dataLoading: '=?ngDisabled',
        availableStatusSets: '=?'
      }
    });

  /* @ngInject */
  function UserCourseStatusFilterSelectorController($scope, $location, userCoursesService, _) {
    var vm = this;
    var statusDictionary = userCoursesService.getStatusDictionary();
    vm.statusDict = {};
    vm.onOptionChanged = onOptionChanged;
    vm.$onInit = activate;

    //////////

    function activate() {
      if (vm.availableStatusSets) {
        var setLength = vm.availableStatusSets.length;
        for (var i = 0; i < setLength; i++) {
          var index = vm.availableStatusSets[i];
          vm.statusDict[index] = statusDictionary[index];
        }
      } else {
        vm.statusDict = statusDictionary;
      }

      var searchQuery = $location.search();
      checkStatusesAndBindToFilter(searchQuery.statuses);

      $scope.$on('event:filterStatusInit', function (event, statuses) {
        checkStatusesAndBindToFilter(statuses);
      });
    }

    function checkStatusesAndBindToFilter(statuses) {
      if (hasStatuses(statuses)) {
        vm.statusFilter = vm.statusDict[statuses];
        onStatusChanged();
      }
    }

    function hasStatuses(statuses) {
      return !!(statuses && vm.statusDict[statuses]);
    }

    function onStatusChanged() {
      if (vm.statusFilter) {
        var statuses;

        _.each(vm.statusDict, function (statusSet, statusesName) {
          if (angular.equals(statusSet, vm.statusFilter)) {
            statuses = statusesName;
          }
        });

        var searchQuery = $location.search();
        angular.extend(searchQuery, {statuses: statuses});
        $location.search(searchQuery);

        $scope.$emit('event:filterStatusChanged', vm.statusFilter, statuses);
      }
    }

    function onOptionChanged(){
      var searchQuery = $location.search();
      if (searchQuery.page) {
        searchQuery.page = 1;
        $location.search(searchQuery);
      }
      onStatusChanged();
    }
  }
})();
