(function() {

  angular.module('kmi.lms.bms.user.learning')
    .component('exportReminderComponent', {
      controller: ExportReminderComponentController,
      controllerAs: 'vm',
      bindings: {
        course: '<',
        registration: '<'
      },
      template: require('ajs/custom_modules/bms/user/learning/components/export-reminder.html').default
    });

  /* @ngInject */
  function ExportReminderComponentController(eventExportService) {
    var vm = this;

    vm.exportReminder = exportReminder;

    function exportReminder() {
      vm.registration.course = vm.course;

      eventExportService.addToCalendarDialog(vm.registration);
    }
  }
})();
