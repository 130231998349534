(function () {

  angular
    .module('kmi.lms.bms.user')
    .component('bmsCustomUserAttributes', {
      template: require('ajs/custom_modules/bms/user/registration/components/custom-attributes.html').default,
      controller: BmsCustomUserAttributesController,
      controllerAs: 'vm',
      bindings: {
        viewMode:'<?',
        user: '=',
        formOptions: '=?',
      }
    });

  /* @ngInject */
  function BmsCustomUserAttributesController($scope, groupService, attributesService, customUserAttributesService) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      groupService.getDefaultSelfRegGroups()
        .then(function (dfGroups) {
          if (dfGroups.length) {
            vm.portalGroup = dfGroups[0].group;

            customUserAttributesService
              .getAttributeCategories({
                withAttributes: true,
                groups: angular.toJson([vm.portalGroup.id])
              })
              .then(function (categories) {
                vm.categories = categories;
              });
          }
        });

      $scope.$on('event:customAttributes.saving', function (event, user) {
        user.attributes = [];

        attributesService.prepareAttributesForSave(user, vm.categories);
      });
    }
  }

})();
