(function () {

  angular
    .module('kmi.lms.bms.user.courses')
    .component('externalCourseCategoriesSelection', {
      template: require('ajs/custom_modules/bms/user/courses/external/edit/external-course-categories-selection.html').default,
      controller: CourseHoursInCollectionsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        hasTrainingCategories: '=',
        readOnly: '=?',
        adminView: '=?'
      }
    });

  /* @ngInject */
  function CourseHoursInCollectionsController(elms, searchService, _) {
    var vm = this,
      searchProvider = null,
      searchParams = {
        type: 'training_category',
      };

    vm.formatDigit = elms.formatDigit;
    vm.addTrainingCategory = addTrainingCategory;
    vm.removeTrainingCategory = removeTrainingCategory;
    vm.filterSelectedCategories = filterSelectedCategories;
    vm.$onInit = onInit;

    function onInit() {
      searchProvider = searchService.get({
        initialRequestItemCount: 300,
        sequentRequestItemsCount: 300,
        mode: vm.adminView ? 'admin':'public'
      });

      loadCollections();
    }

    function filterSelectedCategories(category) {
      return !_.find(vm.course.trainingCategories, function (trainingCategoryCourse) {
        return trainingCategoryCourse.trainingCategory.id === category.id;
      });
    }

    function loadCollections() {
      vm.loading = true;
      searchProvider.search(searchParams)
        .then(function (response) {
          vm.trainingCategories = processItems(response.data.items);
          vm.hasTrainingCategories = vm.trainingCategories.length > 0 || _.get(vm.course, 'trainingCategories.length');
        })
        .finally(function () {
          vm.loading = null;
        });

      function processItems(items) {
        return _.map(items, function (item) {
          item.id = parseInt(item.id);
          return item;
        });
      }
    }

    function addTrainingCategory() {
      var hours = parseFloat(vm.trainingCategoryHours) || 0;

      if (!vm.course.trainingCategories) {
        vm.course.trainingCategories = [];
      }

      vm.course.trainingCategories.push({hours: hours, trainingCategory: angular.copy(vm.availableTrainingCategory)});

      vm.trainingCategoryHours = null;
      vm.availableTrainingCategory = null;
      if (vm.editTrainingCategoryHoursForm) {
        vm.editTrainingCategoryHoursForm.$setPristine();
      }
    }

    function removeTrainingCategory(trainingCategory) {
      vm.course.trainingCategories = _.without(vm.course.trainingCategories, trainingCategory);
    }
  }
})();
