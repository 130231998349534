(function () {

  angular.module('kmi.lms.bms.user.learning')
    .directive('userCourseTableRow', UserCoursesTableRow);

  function UserCoursesTableRow() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        item: '=',
        columns: '=',
        statusSet: '=',
        actual: '=?'
      },
      template: require('ajs/custom_modules/bms/user/learning/components/table-row/template.html').default,
      controller: UserCoursesTableRowController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function UserCoursesTableRowController($scope, courseRegistrationStatus, userCoursesService, currentUser, _,
    notificationService, backUrlService, globalConfig, elms) {
    var vm = this, previousTargetCompletionDate;
    vm.$onInit = onInit;

    vm.datePickerOpened = false;
    vm.setTargetDateAllowed = false;
    vm.visible = true;
    vm.today = new Date();
    vm.statusDict = courseRegistrationStatus;
    vm.backUrl = backUrlService;
    vm.appSettings = globalConfig.settings;
    vm.showHideChildren = showHideChildren;
    vm.getStatusName = getStatusName;
    vm.checkSectionVisibility = checkSectionVisibility;

    vm.isNumber = angular.isNumber;
    vm.formatDigit = elms.formatDigit;

    function onInit() {
      vm.item.visible = vm.visible;
      previousTargetCompletionDate = vm.item.lastRegistration ? vm.item.lastRegistration.targetCompletionDate :
        undefined;
      activate();
    }

    function activate() {
      vm.score = setScore(vm.item);
      vm.registration = getRegistration(vm.item);

      $scope.$watch('vm.statusSet', function () {
        var filterResult = filters(vm.item);
        if (!vm.item.type || _.includes(['externalRecord', 'course'], vm.item.type)) {
          vm.visible = filterResult;
          vm.item.visible = !!vm.visible;
        } else if (_.includes(['compilation'], vm.item.type)) {
          vm.visible = filterByStatus(vm.item);
          vm.item.opened = !!vm.visible;
        } else {
          vm.item.opened = filterResult;
          vm.disabled = !vm.item.items || !vm.item.items.length || !filterResult;
        }
      });

      $scope.$watch('vm.item.lastRegistration.targetCompletionDate', function () {
        vm.datePickerOpened = false;

        if (vm.setTargetDateAllowed && vm.item.lastRegistration && !vm.changeTargetDateLocked &&
          vm.item.lastRegistration.targetCompletionDate !== previousTargetCompletionDate) {
          vm.changeTargetDateLocked = userCoursesService.setTargetCompletionDate({
            registrationId: vm.item.lastRegistration.id ? vm.item.lastRegistration.id : null,
            courseId: vm.item.course.id,
            userId: currentUser.get().id,
            targetCompletionDate: vm.item.lastRegistration.targetCompletionDate
          }).then(function (res) {
            previousTargetCompletionDate = vm.item.lastRegistration.targetCompletionDate;
            if (res.status_id) {
              vm.item.lastRegistration.statusId = res.status_id;

              if (!_.get(vm.item, 'lastRegistration.id')) {
                angular.extend(vm.item.lastRegistration, res);
              }
            }
          }).catch(function (error) {
            if (!vm.item.lastRegistration.id) {
              vm.item.lastRegistration = undefined;
            } else {
              vm.item.lastRegistration.targetCompletionDate = previousTargetCompletionDate;
            }
            notificationService.error(error.data, 5e3);
          }).finally(function () {
            vm.changeTargetDateLocked = false;
          });
        }
      });
    }

    function checkSectionVisibility() {
      if (vm.item && vm.item.type === 'section') {
        // check if visible all child items. Set false if not exists visible items
        vm.visible = !!_.find(vm.item.items || [], function (item) {
          return item.visible;
        });
        vm.item.visible = vm.visible;
        return vm.visible;
      } else {
        return false;
      }
    }

    function getStatusName(statusId) {
      return courseRegistrationStatus.names[statusId];
    }

    function filters(item) {
      var stack = [], node;
      stack.push(item);
      while (stack.length > 0) {
        node = stack.pop();

        if (filterByAvailableEvaluation(node)) {
          return true;
        }

        if (filterByStatus(node)/* && addAnotherFilterHere() */) {
          return true;
        } else if (node.items && node.items.length) {
          for (var i = node.items.length - 1; i >= 0; i--) {
            stack.push(node.items[i]);
          }
        }
      }
      return false;
    }

    function filterByStatus(node) {
      var status = setStatus(node);
      return status && (!vm.statusSet || vm.statusSet.indexOf(status) >= 0);
    }

    function filterByAvailableEvaluation(node) {
      if(vm.statusSet === 'pending_evaluation') {
        return node && node.statusId === courseRegistrationStatus.evaluationPending;
      } else {
        return false;
      }
    }

    function setStatus(node) {
      var status;

      if (!node.type || _.includes(['externalRecord', 'course', 'compilation'], node.type)) {
        if (vm.actual === 'last') {
          status = node.lastRegistration ? node.lastRegistration.statusId : null;
        } else {
          status = node.lastCompletedRegistration ? node.lastCompletedRegistration.statusId : node.statusId;
        }
        status = status || courseRegistrationStatus.notStarted;
      }
      return status;
    }

    function setScore(node) {
      var score, scorePoints;
      if (!node.type || node.type === 'course' || node.type === 'compilation') {
        if (vm.actual === 'last') {
          score = node.lastRegistration ? node.lastRegistration.courseGradePercentage : null;
          scorePoints = node.lastRegistration ? node.lastRegistration.courseGradePoints : null;
        } else {
          score = node.lastCompletedRegistration ? node.lastCompletedRegistration.courseGradePercentage :
            node.highestScorePercentage;
          scorePoints = node.lastCompletedRegistration ? node.lastCompletedRegistration.courseGradePoints :
            node.highestScorePoints;
        }
      }

      var scoreText = '';

      if (vm.isNumber(scorePoints)) {
        scoreText += vm.formatDigit(scorePoints) + ' pt';
      }

      if (vm.isNumber(score)) {
        if (scoreText) {
          scoreText += ' / ' + vm.formatDigit(score) + '%';
        } else {
          scoreText = vm.formatDigit(score) + '%';
        }
      }

      return scoreText;
    }

    function getRegistration(node) {
      var registration;

      if (!node.type || _.includes(['externalRecord', 'course', 'compilation'], node.type)) {
        registration = (vm.actual === 'last') ? node.lastRegistration : node.lastCompletedRegistration;
      }

      return registration;
    }

    function showHideChildren() {
      if (!vm.disabled) {
        vm.item.opened = !vm.item.opened;
      }
    }
  }
})();
