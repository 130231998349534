(function () {

  angular
    .module('kmi.lms.bms.user.courses', [
      'kmi.lms.user.certificates'
    ])
    .config(moduleConfig);

  /* @ngInject*/
  function moduleConfig($stateProvider, globalConfig) {
    $stateProvider
      .state('edit.certificationProof', {
        url: '/certification-proof/:courseId?offeredCourseId',
        params: {
          courseId: null,
          offeredCourseId: null
        },
        data: {
          label: 'Proof of Certification',
          availableFor: ['regularUser', 'admin'],
          adminRoleLimits: globalConfig.settings.view === 'admin' ? [1, 20, 90] : null //eLMS Admin, Course Attendance Manager, Batch Reg Manager
        },
        component: 'editExternalCourse',
        resolve: {
          course: function ($transition$, ExternalCourse, currentUser, globalConfig) {
            if ($transition$.params().courseId) {
              return ExternalCourse.get({
                userId: 0,
                courseId: $transition$.params().courseId,
                permitted_for: globalConfig.settings.view === 'admin' ? ['courseRegistration.add', 'courseRegistration.edit'] : null
              }).$promise;
            }
            else {
              return new ExternalCourse({
                userId: currentUser.get().id,
                user: currentUser.get(),
                formatId: 117,
                statusId: null
              });
            }
          }
        }
      });
  }


})();

