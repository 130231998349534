(function () {

  angular
    .module('kmi.lms.search')
    .run(moduleRun);

  /* @ngInject */
  function moduleRun($state, searchFilters, searchFilterObject, searchFilterCollectionTypes, searchFilterCourseProviders) {
    var searchState = $state.get('main.search');
    if (searchState) {
      searchState.data.availableFor = ['anonymous', 'regularUser', 'admin'];
    }

    searchFilters.onInit(onInit);

    function onInit() {
      var objectFilter = searchFilters.getFilter(searchFilterObject),
        tagFilter = new searchFilters.TagFilter(),
        courseFormatFilter = new searchFilters.CourseFormatFilter(),
        subjectFilter = new searchFilters.SubjectFilter(),
        creditTypeFilter = new searchFilters.CreditTypeFilter(),
        // competencyFilter = new searchFilters.CompetencyFilter(),
        lsFilter = new searchFilters.LsFilter(),
        trainingCategoriesFilter = searchFilters.getFilter(searchFilterCollectionTypes.getTrainingCategoriesFilterConfig({mode: 'public'})),
        filterOrganization = searchFilters.getFilter(searchFilterCourseProviders.getOrganizationFilterConfig({mode: 'promptingFilter'}));

      searchFilters.associateFilters(objectFilter, courseFormatFilter);
      searchFilters.associateFilters(objectFilter, trainingCategoriesFilter);
      searchFilters.associateFilters(objectFilter, filterOrganization);

      return {
        ui: [objectFilter, courseFormatFilter, trainingCategoriesFilter, filterOrganization],
        advanced: [],
        all: [objectFilter, courseFormatFilter, trainingCategoriesFilter, tagFilter, subjectFilter, creditTypeFilter,
          /* competencyFilter, */ lsFilter, filterOrganization]
      };
    }
  }

})();
