(function () {

  angular.module('kmi.lms.bms.home', [
    'kmi.lms.core',
    'kmi.lms.course.common',
    'kmi.lms.search.common',
    'kmi.lms.user.notifications'
  ])
    .config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.home', {
        'url': '^/home?state',
        'component': 'bmsHome',
        'params': {
          'state': null
        },
        'data': {
          'label': 'Home',
          'availableFor': ['regularUser', 'admin']
        }
      });
  }
})();

