(function () {

  angular.module('kmi.lms.bms.user.learning')
    .component('userLearningView', {
      template: require('ajs/custom_modules/bms/user/learning/user-learning.html').default,
      controller: UserLearningController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UserLearningController($scope, currentUser, courseRegistrationStatus, userCoursesService, $location,
    courseEnums, userLearningViewConstants, _) {
    var vm = this;

    vm.userId = currentUser.get().id;
    vm.courseRegistrationStatus = courseRegistrationStatus;
    vm.courseFormatType = courseEnums.formatType;
    vm.views = userLearningViewConstants.views;

    vm.viewChanged = viewChanged;
    vm.exportCourses = exportCourses;
    vm.getTranscriptUrl = getTranscriptUrl;


    activate();

    function activate() {

      // Listen for the loading event from the nested views in order to disable UI controls
      $scope.$on('event:dataLoading', function (event, loading) {
        vm.dataLoading = loading;
      });

      $scope.$on('event:filterStatusChanged', function (event, filter, statuses) {
        if (filter && filter.statuses === 'pending_evaluation') {
          vm.activeViewSelect = 'all';
          vm.viewChanged();
        }

        // TODO We need to refactor this controlls to avoid all custom events. Only one source should be: on location
        // change.
        vm.statusFilter = filter;
        vm.activeStatuses = statuses;
        statusChanged(statuses);
      });

      var searchQuery = $location.search();

      vm.viewsDict = _.keyBy(vm.views, 'id');

      if (searchQuery.view in vm.viewsDict) {
        vm.activeView = vm.activeViewSelect = searchQuery.view;
      } else { // Preselect filter if corresponding content exists
        vm.activeView = 'all';
        vm.activeViewSelect = vm.activeView;
      }

      vm.activeViewName = vm.viewsDict[vm.activeView].name;

      $scope.$watch(function () {
        return $location.search();
      }, function (query) {
        if (query.view in _.keyBy(vm.views, 'id')) {
          vm.activeView = vm.activeViewSelect = query.view;
        } else if (!query.statuses || !vm.activeView) {
          vm.activeView = 'all';
          vm.activeViewSelect = vm.activeView;
        }

        if (query.statuses && query.statuses === 'pending_evaluation' && query.view !== 'all') {
          vm.statusFilter = null;
          statusChanged();
        }

        if (query.statuses && query.statuses !== vm.activeStatuses) {
          $scope.$broadcast('event:filterStatusInit', query.statuses);
        }
      });
    }

    function statusChanged(statuses) {
      var searchQuery = $location.search();

      angular.extend(searchQuery, {statuses: statuses});
      $location.search(searchQuery);
    }

    function exportCourses() {
      // Broadcast event to trigger export from the nested component
      $scope.$broadcast('user:courses:export');
    }

    function viewChanged() {
      var searchQuery = $location.search();
      delete searchQuery.filter;
      delete searchQuery.dir;
      angular.extend(searchQuery, {view: vm.activeViewSelect});
      $location.search(searchQuery);
    }

    function getTranscriptUrl() {
      return userCoursesService.getTranscriptUrl(currentUser.get());
    }
  }
})();
