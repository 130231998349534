(function () {
  angular.module('kmi.lms.core')
    .config(coreModuleConfig);

  function coreModuleConfig($stateProvider) {
    $stateProvider
      .state('main', {
        'url': '/u',
        'template': require('ajs/custom_modules/bms/app/base-view.html').default,
        'abstract': true
      })
      .state('mainPrint', {
        'url': '/p',
        'template': require('ajs/custom_modules/bms/app/base-view-print.html').default,
        'abstract': true
      })
      .state('prompt', {
        'url': '/prompt',
        'component': 'bmsPromptView',
        'abstract': true
      });
  }

  angular.module('elmsSpaApp')
    .run(moduleRun);

  function moduleRun(
    $window, $state, $transitions, siteSettingService, authorizationService, navigationService,
    _, $timeout, $stateRegistry
  ) {
    var siteSetting = siteSettingService.getSiteSetting();
    var basePath = siteSetting.basePath;
    var defaultPortal = !basePath || basePath === 'lms';

    var reportsState = $state.get('main.admin.reports');
    if (reportsState) {
      reportsState.data.label = 'Training Reports';
    }

    var myReportsState = $state.get('main.reportTypeList');
    if (myReportsState) {
      myReportsState.data.label = 'Your Training Reports';
    }

    var userRegistrationState = $state.get('prompt.userRegistration');
    if (userRegistrationState && defaultPortal) {
      $stateRegistry.deregister('prompt.userRegistration');
    }

    $timeout(function () {
      var newsState = $state.get('main.news');
      if (newsState) {
        newsState.data.label = 'Training Announcements';
      }
    });

    $transitions.onStart({to: 'prompt.login'}, function (transition) {
      if (transition.params().inplace || !defaultPortal || !siteSetting.integrationLoginPath) {
        return;
      }

      // Open integration Login
      transition.abort();

      var targetState = navigationService.getTargetState(false),
        fromState = transition.from(),
        fromParams = _.pickBy(transition.params('from'), function (n) {
          return !!n;
        }),
        targetUrl;

      if (targetState) {
        targetUrl = _.find(authorizationService.getAuthorizedStates(), function (item) {
          return item.name === targetState.name && angular.equals(item.params, _.pickBy(targetState.params, function (n) {
            return !!n;
          }));
        }) ? null : $state.href(targetState.name, targetState.params);
      }

      if (!targetUrl && fromState && fromState.name) {
        targetUrl = _.find(authorizationService.getAuthorizedStates(), function (item) {
          return item.name === fromState.name && angular.equals(item.params, fromParams);
        }) ? null : $state.href(fromState, fromParams);
      }

      $window.location.href = siteSetting.integrationLoginPath + (targetUrl ? '?target_url=' + encodeURIComponent(targetUrl) : '');

    });
  }

  angular
    .module('kmi.lms.components')
    .value('fileMaxSize', 5120);
})();
