(function () {

  angular
    .module('kmi.lms.bms.user', [
      'kmi.lms.core',
      'kmi.lms.user.common',
      'kmi.lms.customAttributes.user'
    ])
    .config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('prompt.userRegistration', {
        url: '/register',
        component: 'bmsUserSelfRegistration',
        data: {
          label: 'Registration',
          availableFor: ['anonymous']
        }
      });
  }
})();
