(function () {

  angular.module('elmsSpaApp')
    .component('bmsNavigationComponent', {
      template: require('ajs/custom_modules/bms/app/navigation.html').default,
      controller: NavigationController,
      controllerAs: 'vm',
      bindings: {
        nonInteractive: '<?'
      }
    });

  /* @ngInject */
  function NavigationController($scope, $window, rootScopeService, $state, currentUser,
    menuService, drawerService) {
    var vm = this;

    vm.state = $state;
    vm.allTabs = []; //default blank tabs list

    vm.userSettings = currentUser.get();

    vm.login = login;
    vm.drawerService = drawerService;

    vm.$onInit = activate;
    vm.print = print;
    vm.currentDate = new Date();

    function activate() {
      // initializes menu on start of the application
      initUserEnvironment();

      $scope.$on('event:userAgencyLoaded', function () {
        // set menu for the anonymous user
        initUserEnvironment();
      });

      function initUserEnvironment() {
        vm.allTabs = $scope.allTabs = menuService.getMenuItems();
      }
    }

    function login() {
      rootScopeService.broadcast('event:auth-loginRequired');
    }

    function print() {
      $window.print();
    }
  }
})();

