(function () {

  angular.module('kmi.lms.bms.user.learning')
    .component('userCourses', {
      template: require('ajs/custom_modules/bms/user/learning/components/user-courses.html').default,
      controller: UserCoursesController,
      controllerAs: 'vm',
      bindings: {
        userId: '=',
        filters: '=',
        actual: '=?',
        count: '=',
        serverSort: '=sort',
        showStatistics: '=',
        statusSet: '=',
        columns: '=?',
        showPaging: '=?'
      }
    });

  /* @ngInject */
  function UserCoursesController($scope, UserCourse, userCoursesService, sjcl, $location) {
    var vm = this,
      cacheKey, filtersStr;

    var defaultSorting = {field: 'lastCompletionDate', dir: 'desc'};

    vm.progress = 0;
    vm.total = 0;
    vm.countAll = 0;
    vm.itemsPerPage = 20;
    vm.pageChanged = pageChanged;
    vm.registrationStatuses = null;

    vm.$onInit = activate;

    function activate() {
      var searchQuery = $location.search();
      vm.sorting = vm.serverSort || defaultSorting;
      vm.currentPage = parseInt(searchQuery.page) || 1;

      if(searchQuery.filter){
        vm.sorting = {field: searchQuery.filter, dir: searchQuery.dir};
      } else {
        angular.extend(searchQuery, {filter: vm.sorting.field, dir: vm.sorting.dir});
        $location.search(searchQuery);
      }

      filtersStr = angular.toJson(vm.filters);
      cacheKey = getCacheKey();

      $scope.$on('user:courses:export', exportCourses);

      // Reload courses from WS when some course registration data changed
      $scope.$on('event:course.registration.action.finished', function () {
        loadData();
      });

      bindCachedData();
      loadData();

      $scope.$watch('vm.loadingPromise', function(){
        // Send event to the parent controller
        $scope.$emit('event:dataLoading', !!vm.loadingPromise);
      });

      if (vm.showPaging) {
        $scope.$watch(function () { return $location.search(); }, locationChanged, true);
      }
    }

    function loadData() {
      var terminated = false;
      const query: any = {
        userId: vm.userId,
        query: filtersStr,
        sort: {
          field: vm.sorting.field.toString().split(/(?=[A-Z])/).join('_').toLowerCase(),
          dir: vm.sorting.dir,
          actual: vm.actual
        }
      };

      if (vm.showPaging) {
        query.statusSet = angular.toJson(vm.statusSet);
        query.count = vm.itemsPerPage;
        query.page = vm.currentPage;
      } else if (vm.count) {
        query.count = vm.count;
      }

      vm.loadingPromise = UserCourse.query(query);
      vm.loadingPromise.$promise
        .then(function (response) {
          vm.courses = response.courses;
          vm.progress = response.stats.completed;
          vm.total = response.stats.total;
          vm.countAll = response.stats.countAll;
          vm.registrationStatuses = vm.statusSet;

          if (vm.showPaging && vm.total <= vm.itemsPerPage) {
            $location.search(angular.extend($location.search(), {page: null})).replace();
          }

          sessionStorage.setItem(cacheKey, JSON.stringify(response));
        }, function (response) {
          terminated = response.status === -1;
        })
        .finally(function () {
          if (!terminated) {
            vm.loadingPromise = null;
          }
        });
    }

    /**
     * @description
     * Changes url to get list of courses for the new page.
     */
    function pageChanged() {
      // skip the event if all data are loaded
      if (vm.courses && vm.total <= vm.courses.length) {
        return;
      }

      var searchQuery = $location.search();

      angular.extend(searchQuery, {page: vm.currentPage.toString()});
      $location.search(searchQuery);
    }

    function bindCachedData() {
      var cachedModel = cacheKey && JSON.parse(sessionStorage.getItem(cacheKey));
      if (cachedModel) {
        vm.courses = cachedModel.courses;
        vm.progress = cachedModel.stats.completed;
        vm.countAll = cachedModel.stats.countAll;
        vm.total = cachedModel.stats.total;
        vm.registrationStatuses = vm.statusSet;
      }
    }

    function exportCourses() {
      userCoursesService.exportCourses(vm.userId, vm.filters.include_external ? {data: {include_external: true}} : null);
    }

    function locationChanged(value, oldValue) {
      if ((vm.countAll <= vm.itemsPerPage) && (vm.total <= vm.itemsPerPage) && (vm.countAll <= vm.total)) {
        vm.registrationStatuses = vm.statusSet;
        return;
      }

      if ((!vm.showPaging || value === oldValue) ||
          (value.statuses === oldValue.statuses && vm.total <= vm.itemsPerPage)) {
        return;
      }

      if (vm.loadingPromise) {
        vm.loadingPromise.$cancelRequest();
      }

      cacheKey = getCacheKey();
      vm.sorting = (value.filter) ? {field: value.filter, dir: value.dir} : defaultSorting;
      vm.currentPage = parseInt(value.page) || 1;

      bindCachedData();
      loadData();
    }

    function getCacheKey() {
      var filters = (vm.statusSet) ? angular.extend({statuses: vm.statusSet}, vm.filters) : vm.filters;
      var key = angular.toJson(filters);

      return ['userCourses:', vm.userId, sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(key))].join('');
    }
  }
})();
