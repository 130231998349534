(function () {

  angular.module('kmi.lms.bms.user.learning')
    .component('userExternalCourses', {
      template: require('ajs/custom_modules/bms/user/learning/external/external-courses-list.html').default,
      controller: UserExternalCoursesController,
      controllerAs: 'vm',
      bindings: {
        userId: '<',
        statusSet: '='
      }
    });

  /* @ngInject */
  function UserExternalCoursesController($scope, ExternalCourse, userCoursesService, courseRegistrationStatus,
    $location, _) {
    var vm = this;

    vm.columns = ['completedDate', 'status'];
    vm.defaultSorting = {
      field: 'title',
      dir: 'asc'
    };
    vm.itemsPerPage = 20;
    vm.$onInit = onInit;
    vm.pageChanged = pageChanged;

    function onInit() {
      var searchQuery = $location.search();
      vm.sorting = vm.defaultSorting;
      vm.currentPage = parseInt(searchQuery.page) || 1;

      if (searchQuery.filter) {
        vm.sorting = {
          field: searchQuery.filter,
          dir: searchQuery.dir
        };
      } else {
        angular.extend(searchQuery, {
          filter: vm.sorting.field,
          dir: vm.sorting.dir
        });
        $location.search(searchQuery);
      }

      loadData();

      $scope.$watch('vm.statusSet', discardPage);
      $scope.$watch('vm.sorting', discardPage);

      $scope.$on('event:course.updateData', function () {
        loadData();
      });

      $scope.$on('user:courses:export', exportExternalCourses);

      $scope.$watch(function () {
        return $location.search();
      }, function (value, oldValue) {
        // url didn't change or it was changed by the internal function 'sortOrder'
        if (_.isEqual(value, oldValue)) {
          return;
        }

        vm.sorting = (value.filter) ? {
          field: value.filter,
          dir: value.dir
        } : vm.defaultSorting;
        vm.currentPage = parseInt(value.page) || 1;

        prepareCourses();
      }, true);
    }

    function discardPage(value, oldValue) {
      if (_.isEqual(value, oldValue)) {
        return;
      }

      vm.currentPage = 1;
      pageChanged();
    }

    /**
     * @description
     * Changes url to get list of courses for the new page.
     */
    function pageChanged() {
      var searchQuery = $location.search();

      angular.extend(searchQuery, {page: vm.currentPage.toString()});
      $location.search(searchQuery);
    }

    function loadData() {
      vm.loadingPromise = ExternalCourse.query({userId: vm.userId}).$promise
        .then(function (courses) {
          vm.externalCourses = courses;
          angular.forEach(vm.externalCourses, function (course) {
            angular.extend(course, {
              lastCompletionDate: course.completionDate,
              statusNameSorting: course.statusId ? courseRegistrationStatus.names[course.statusId] : 'Incomplete'
            });
          });
          prepareCourses();

        })
        .finally(function () {
          vm.loadingPromise = null;
        });
    }

    function prepareCourses() {
      vm.externalCoursesFiltered = _.filter(vm.externalCourses, function (course) {
        return !vm.statusSet || _.includes(vm.statusSet, course.statusId || courseRegistrationStatus.notStarted);
      });

      vm.externalCoursesChunks = _.chunk(_.orderBy(vm.externalCoursesFiltered, [vm.sorting.field,
        'title'], [vm.sorting.dir, 'asc']), vm.itemsPerPage);
    }

    function exportExternalCourses() {
      userCoursesService.exportExternalCourses(vm.userId);
    }
  }
})();
