import {
  configureApplicationRuntimeSettings,
  configureApplicationSettings,
} from '../../modules/app/configuration/application-configuration';


require('ajs/vendors');
require('ajs/modules/app/models/class.ts');
require('ajs/global-dependencies.ts');
require('ajs/global-scope.ts');
require('ajs/modules/app/_init.ts');

declare const angular: angular.IAngularStatic;

angular
  .module('elmsSpaApp', ['ui.router', 'ui.router.upgrade', 'http-auth-interceptor', 'ui.bootstrap', 'angular-ladda',
    'ngCookies', 'ngAnimate', 'ngResource', 'ui.mask', 'ngSanitize', 'ngAria',
    'globalDependencies', 'kmi.lms.services', 'blueimp.fileupload',
    'kmi.lms.core',
    'kmi.lms.core.login',
    'kmi.lms.components',
    'kmi.lms.bms.home',
    'kmi.lms.bms.user.courses',
    'kmi.lms.user.lsCompilations',
    'kmi.lms.user.certificates',
    'kmi.lms.search',
    'kmi.lms.user',
    'kmi.lms.dictionary',
    'kmi.lms.user.notifications',
    'kmi.lms.quiz', 'kmi.lms.course.quizzes',
    'kmi.lms.course.common',
    'kmi.lms.course.details',
    'kmi.lms.course.registrations',
    'kmi.lms.advertising',
    'kmi.lms.bms.user.learning',
    'kmi.lms.bms.user', 'kmi.lms.bms.authorization',
    'kmi.lms.user.achievements',
    'kmi.lms.passwords'
  ])
  .config(configureApplicationSettings)
  .run(configureApplicationRuntimeSettings);

require('ajs/core-modules');

require('ajs/custom_modules/bms/_init.ts');
require('ajs/custom_modules/bms/app/navigation-component.ts');
require('ajs/custom_modules/bms/app/prompt-view-component.ts');
require('ajs/custom_modules/bms/login/common-wealth-banner.ts');
require('ajs/modules/user/notifications/tile/notifications-directive.ts');
require('ajs/custom_modules/bms/user/_init.ts');
require('ajs/custom_modules/bms/user/learning/_init.ts');
require('ajs/custom_modules/bms/user/learning/user-learning-component.ts');
require('ajs/custom_modules/bms/user/learning/external/external-courses-list-component.ts');
require('ajs/custom_modules/bms/user/learning/components/progress-chart-directive.ts');
require('ajs/custom_modules/bms/user/learning/components/export-reminder-component.ts');
require('ajs/custom_modules/bms/user/learning/components/status-filter-selector-directive.ts');
require('ajs/custom_modules/bms/user/learning/components/user-courses-directive.ts');
require('ajs/custom_modules/bms/user/learning/components/table-row/directive.ts');
require('ajs/custom_modules/bms/user/learning/components/table-view/directive.ts');
require('ajs/custom_modules/bms/user/learning/summary/badges-summary-tile-directive.ts');
require('ajs/custom_modules/bms/user/ls-compilations/_init.ts');
require('ajs/custom_modules/bms/user/ls-compilations/ls-compilations-service.ts');
require('ajs/custom_modules/bms/user/ls-compilations/user-ls-compilations-directive.ts');
require('ajs/custom_modules/bms/user/edit/components/portal-selection-component.ts');
require('ajs/custom_modules/bms/user/registration/user-registration-component.ts');
require('ajs/custom_modules/bms/user/registration/components/custom-attributes-component.ts');
require('ajs/custom_modules/bms/user/registration/components/account-details-component.ts');
require('ajs/custom_modules/bms/user/user-config.ts');
require('ajs/custom_modules/bms/user/view/user-short-info.ts');
require('ajs/custom_modules/bms/user/modal/user-qr-code-dialog-component.ts');
require('ajs/custom_modules/bms/user/user-service.ts');
require('ajs/custom_modules/bms/user/courses/external/_init.ts');
require('ajs/custom_modules/bms/user/courses/external/edit/edit-external-course-component.ts');
require('ajs/custom_modules/bms/user/courses/external/edit/external-course-categories-selection-component.ts');
require('ajs/custom_modules/bms/home/_init.ts');
require('ajs/custom_modules/bms/home/home-component.ts');
require('ajs/custom_modules/bms/home/notifications-component.ts');
require('ajs/modules/user/notifications/modal/notifications-component.ts');
require('ajs/custom_modules/bms/search/_init.ts');
require('ajs/custom_modules/bms/course/_init.ts');
require('ajs/custom_modules/bms/authorization/_init.ts');
require('ajs/custom_modules/bms/authorization/portal-compliance-component.ts');

require('src/projects/bms/index.module.ts');
