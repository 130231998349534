(function(){

  angular
    .module('kmi.lms.bms.user.learning', [
      'kmi.lms.course.registrations',
      'kmi.lms.user.learning'
    ])
    .value('userLearningViewConstants', Object.freeze({
      views: [
        { id: 'all', name: 'View All'},
        { id: 'recent', name: 'Recent Courses'},
        { id: 'lsCompilations', name: 'Programs'},
        { id: 'certifications', name: 'My Certifications'},
        { id: 'scheduledEvents', name: 'Scheduled Events'},
        { id: 'proofOfCertifications', name: 'Proof of Certifications'}
      ]
    }))
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.learning', {
        url: '^/learning?view&statuses&target&filter&dir&page',
        params: {
          view: null,
          target: null,
          statuses: {
            value: null,
            dynamic: true
          },
          filter: {
            value: null,
            dynamic: true
          },
          dir: {
            value: null,
            dynamic: true
          },
          page: {
            value: null,
            dynamic: true
          }
        },
        data: {
          label: 'Learning dashboard',
          availableFor: ['regularUser', 'admin']
        },
        component: 'userLearningView',
        onEnter: function ($state, $stateParams, menuService, _) {
          if (!$stateParams.target) {
            var menuItems = menuService.getMenuItems(),
              defaultLearningState = _.find(menuItems, 'stateParams.target');

            if(defaultLearningState) {
              $state.go(defaultLearningState.state, angular.extend(
                defaultLearningState.stateParams,
                $stateParams,
                {target: defaultLearningState.stateParams.target}));
            }
          }
        }
      });
  }


})();

