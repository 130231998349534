(function () {

  angular.module('kmi.lms.bms.authorization')
    .component('bmsPortalComplianceComponent', {
      template: require('ajs/custom_modules/bms/authorization/portal-compliance.html').default,
      controller: BmsPortalComplianceController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<'
      }
    });


  /* @ngInject */
  function BmsPortalComplianceController(
    $window, $state, navigationService, portalsService, globalConfig, _
  ) {
    var vm = this;

    vm.navigateToPortal = navigateToPortal;

    activate();

    function activate() {
      vm.loading = true;

      portalsService.getUserPortals()
        .then(function (portals) {
          vm.portals = portals;

          if (vm.portals.length) {
            vm.targetPortal = vm.portals[0];
            navigateToPortal();
          }
        })
        .finally(function () {
          vm.loading = null;
        });
    }

    function navigateToPortal() {
      if (vm.targetPortal) {
        redirectToPortal();
      }
    }

    function redirectToPortal(){
      // Deep linking support. Pass target path to the new portal
      // Skip current state in deep linking
      var targetState = navigationService.getTargetState();
      var targetPath = targetState && $state.current && targetState.name === $state.current.name ?
        null : navigationService.getRelativeTargetPath();
      var targetPortal = portalsService.getPortalPath(vm.targetPortal);

      $window.location.href = targetPath ? _.trimEnd(targetPortal, '/') + '/' + targetPath.replace(globalConfig.base, '') : targetPortal;
    }
  }
})();
