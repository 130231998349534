(function () {

  angular.module('kmi.lms.bms.user')
    .factory('bmsUserService', userService);

  /* @ngInject */
  function userService($http, User, apiUrl) {
    return {
      getFromBmsId: function (bmsUserId, query) {
        return $http.get(apiUrl(['/a/integration/bms/users/', bmsUserId, '/'].join('')), {params: query}).then(function (response) {
          return User.getFromObj(response.data);
        });
      },
      getBmsUser: function (userId) {
        return $http.get(['/a/integration/bms/users/elms-users/', userId, '/'].join(''), {cache: true})
          .then(function (response) {
            return response.data;
          });
      }
    };
  }

})();
