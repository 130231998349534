(function () {

  angular.module('kmi.lms.bms.user.learning')
    .directive('userCoursesProgressChart', userCoursesProgressChart);

  function userCoursesProgressChart() {
    return {
      template: require('ajs/custom_modules/bms/user/learning/components/progress-chart.html').default,
      scope: {
        completed: '='
      }
    };
  }
})();
