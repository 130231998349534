(function (){
  angular.module('kmi.lms.bms.authorization', [
    'globalDependencies',
    'kmi.lms.portals'
  ])
    .config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('prompt.portalCompliance', {
        'url': '/portal_compliance',
        'component': 'bmsPortalComplianceComponent',
        'data': {
          'label': 'Portal Compliance',
          'availableFor': ['regularUser', 'admin']
        }
      });
  }
})();
