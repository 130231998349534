(function () {

  angular
    .module('kmi.lms.bms.user.courses')
    .component('editExternalCourse', {
      template: require('ajs/custom_modules/bms/user/courses/external/edit/edit-external-course.html').default,
      controller: EditExternalCourseController,
      controllerAs: 'vm',
      bindings: {
        course: '='
      }
    });

  /* @ngInject */
  function EditExternalCourseController($scope, $state, currentUser, notificationService, moment, User, globalConfig,
    serverErrorHandlerService, backUrlService, courseRegistrationStatus, $uibModal,
    ExternalCertificate, $q, $uiRouterGlobals, Course, securityService,
    urlVersionService, searchService, _) {
    var vm = this;

    vm.forms = {
      submitted: false
    };
    vm.currentUser = currentUser.get();
    vm.adminView = globalConfig.settings.view === 'admin';
    vm.backUrl = backUrlService;

    vm.$onInit = onInit;
    vm.save = save;
    vm.approve = approve;
    vm.decline = decline;
    vm.removeCourse = removeCourse;
    vm.removeExternalCourse = removeExternalCourse;
    vm.addNewCertification = addNewCertification;
    vm.openTranscript = openTranscript;
    vm.isStateAvailable = isStateAvailable;
    vm.addCourse = addCourse;

    function onInit() {
      vm.courseSearchParams = {
        type: 'course',
        active: true,
        approval_status_id: 2,
        expired: true,
        format_id: [117],
        user_id: vm.course.userId
      };

      if (vm.adminView) {
        vm.readOnly = vm.course.statusId === 2;
      } else {
        vm.readOnly = vm.course.statusId;
      }

      vm.course.lmsCourses = [];
      initLmsCourse();

      // Initialize certificate data
      if (vm.course.id) {
        ExternalCertificate.getByRegistration({
          userId: vm.course.userId,
          registrationId: vm.course.id,
          non_elms: true
        }).$promise
          .then(function (certificate) {
            if (certificate && certificate.id) {
              vm.certificate = certificate;
            } else {
              vm.certificate = newCertificate();
            }
          });
      } else {
        vm.certificate = newCertificate();
      }

      User.get(vm.course.userId, {permitted_for: ['courseRegistration.add', 'courseRegistration.edit']})
        .then(function (user) {
          vm.user = user;
        });

      $scope.$on('event:fileUploadProgress', onFileUploadProgress);
      $scope.$watchCollection('vm.course.lmsCourses', function (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          vm.course.lmsCourse = _.first(vm.course.lmsCourses) || null;

          checkMatchingTrainingCategories()
            .then(null, function () {
              vm.course.lmsCourses = oldValue;
              vm.course.lmsCourse = null;
            });
        }
      });

      $scope.$watchCollection('vm.course.trainingCategories',function (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          checkMatchingTrainingCategories()
            .then(null, function () {
              vm.course.trainingCategories = oldValue;
            });
        }
      });

      function onFileUploadProgress(event, isUploading) {
        vm.fileUploadProgress = isUploading;
      }
    }

    function approve() {
      return vm.save(courseRegistrationStatus.approved);
    }

    function decline() {
      vm.savePromise = saveCourseData(courseRegistrationStatus.declined)
        .then(function () {
          backUrlService.goBack();
        });
    }

    function initLmsCourse() {
      if (vm.adminView && vm.course.statusId && vm.course.statusId !== 2 && vm.course.lmsCourse) {
        vm.course.lmsCourses.push(vm.course.lmsCourse);
      }

      if ($uiRouterGlobals.params.offeredCourseId) {
        Course.get({courseId: $uiRouterGlobals.params.offeredCourseId}).$promise
          .then(function (course) {
            vm.course.lmsCourses.push(course);
          });
      }
    }

    function newCertificate() {
      return ExternalCertificate.newCertificate({
        userId: currentUser.get().id,
        registrationId: vm.course.id,
        nonElms: true
      });
    }

    function save(statusId=null) {
      vm.forms.submitted = true;
      vm.targetStatusId = statusId || vm.course.statusId;

      if (isValid()) {
        // Try to save certificate data first in case when course already exists in DB.
        if (vm.course.id) {
          vm.savePromise = saveCertificate()
            .then(function () {
              return saveCourseData(statusId);
            });
        } else {
          vm.savePromise = saveCourseData(statusId)
            .then(function () {
              return saveCertificate();
            });
        }

        return vm.savePromise
          .then(function () {
            backUrlService.goBack();
          })
          .finally(function () {
            vm.savePromise = null;
            vm.editCourseForm.$setPristine();
          });
      } else {
        notificationService.hideInfo();
        notificationService.error('Please fill in all required fields', 2e3);

        return $q.reject(null);
      }
    }

    function isValid() {
      var valid = true;
      if (vm.editCourseForm.$valid) {
        return true;
      }

      if (!vm.course.title) {
        return false;
      }

      if (vm.hasTrainingCategories && (!vm.course.trainingCategories || !vm.course.trainingCategories.length)) {
        return false;
      }

      if (_.includes([courseRegistrationStatus.pending, courseRegistrationStatus.approved], vm.targetStatusId)) {
        valid = valid && !!vm.certificate.file;
      }

      if (vm.targetStatusId === courseRegistrationStatus.approved) {
        valid = valid && !!vm.course.lmsCourse;
      }

      return valid;
    }

    function checkMatchingTrainingCategories() {
      if (!vm.course.lmsCourse || (!_.get(vm.course, 'trainingCategories', []).length  && !_.get(vm.course.lmsCourse, 'trainingCategories', []).length)) {
        return $q.when(true);
      }

      var trainingCategories = vm.course.lmsCourse.trainingCategories;

      var hasMatch = _.find(vm.course.trainingCategories, function (selectedCategory) {
        return _.find(trainingCategories, function (category) {
          return selectedCategory.trainingCategory.id === category.trainingCategory.id && selectedCategory.hours === category.hours;
        });
      });

      if (!hasMatch) {
        return $uibModal.open({
          component: 'mismatchingTrainingCategoriesWarning',
          resolve: {
            trainingCategories: function () {
              return trainingCategories;
            }
          }
        }).result;
      }

      return $q.when(true);
    }

    function removeExternalCourse() {
      vm.savePromise = vm.course.$delete()
        .then(function () {
          backUrlService.goBack();
        })
        .finally(function () {
          vm.savePromise = null;
        });
    }

    function saveCourseData(statusId) {
      var savePromise, params;
      var courseDraft = angular.copy(vm.course);

      courseDraft.startDate = courseDraft.startDate ? moment(courseDraft.startDate).format('YYYY-MM-DD') : null;
      courseDraft.completionDate = courseDraft.completionDate ? moment(courseDraft.completionDate).format('YYYY-MM-DD') : null;

      if (statusId) {
        courseDraft.statusId = statusId;
      }

      params = {
        permitted_for: vm.adminView ? 'courseRegistration.add' : null
      };

      if (courseDraft.id) {
        savePromise = courseDraft.$update(params);
      } else {
        savePromise = courseDraft.$save(params);
      }

      return savePromise
        .then(function handleResponse() {
          angular.extend(vm.course, courseDraft);

        }, serverErrorHandlerService.handleForbiddenError);
    }

    function saveCertificate() {
      var certificateDraft;
      var certSavePromise;

      // Certificate wasn't changed
      if (!vm.certificate.file || angular.isObject(vm.certificate.file)) {
        return $q.when(true);
      }

      vm.certificate.title = vm.course.title;
      vm.certificate.registrationId = vm.course.id;

      certificateDraft = angular.copy(vm.certificate);
      if (certificateDraft.id) {
        certSavePromise = certificateDraft.$update();
      } else {
        certSavePromise = certificateDraft.$save();
      }

      return certSavePromise
        .then(function () {
          urlVersionService.update();
        }, serverErrorHandlerService.handleForbiddenError);
    }

    function removeCourse(courseToRemove) {
      _.remove(vm.course.lmsCourses, function (course) {
        return course.id === courseToRemove.id;
      });
    }

    function addNewCertification() {
      saveConfirmation().then(function () {
        backUrlService.passThroughRedirect('edit.certification_simple', {
          onBehalfId: vm.course.id,
          title: vm.course.title
        });
      });
    }

    function saveConfirmation() {
      if (vm.editCourseForm.$dirty) {
        var modalInstance = $uibModal.open({
          component: 'saveExternalCourseDialog',
          keyboard: false
        });

        return modalInstance
          .result
          .then(function () {
            return save();
          });
      }

      return $q.when(true);
    }

    function openTranscript() {
      saveConfirmation().then(function () {
        $state.go('main.userTranscript', {userId: vm.course.userId});
      });
    }

    function isStateAvailable(stateName) {
      return securityService.isStateAvailable(stateName);
    }

    function addCourse(course) {
      vm.course.lmsCourses.push(course);
    }
  }
})();
