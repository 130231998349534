(function () {

  angular.module('kmi.lms.bms.user.learning')
    .directive('userCoursesTableView', UserCoursesTableView);

  function UserCoursesTableView() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        courses: '=',
        columns: '=?',
        statusSet: '=?',
        actual: '=?',
        sorting: '=?',
        sortingEnabled: '=?',
        sortingOnServer: '=?',
        defaultSorting: '=?',
        dataLoading: '=loading',
      },
      template: require('ajs/custom_modules/bms/user/learning/components/table-view/template.html').default,
      controller: UserCoursesTableViewController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function UserCoursesTableViewController($scope, _,  courseRegistrationStatus, $location, globalConfig) {
    var vm = this;

    vm.predicate = null;
    vm.reverse = null;
    vm.appSettings = globalConfig.settings || {};

    vm.sortOrder = sortOrder;
    vm.isCurrentSortPredicate = isCurrentSortPredicate;

    vm.$onInit = activate;

    //////////

    function activate() {
      if (!vm.defaultSorting){
        vm.defaultSorting = {field: 'course.name', dir: 'desc'};
      }

      vm.predicate = _.get(vm.sorting, 'field', vm.defaultSorting.field);
      vm.reverse = _.get(vm.sorting, 'dir', vm.defaultSorting.dir) === 'desc';

      // Full list of not mandatory columns
      // ['targetDate','completedDate','accessExpirationDate','expirationDate','score','hours','status']

      var defaultColumns = {
        targetDate: true,
        completedDate: true,
        score: true,
        hours: true,
        status: true,
        accessExpirationDate: true
      };

      if (vm.appSettings.registrationExpirationEnabled) {
        defaultColumns.expirationDate = true;
      }

      if (angular.isString(vm.columns)) {
        switch (vm.columns) {
          case 'min':
            vm.columns = {status: true};
            break;
          default:
            vm.columns = defaultColumns;
        }
      } else if (angular.isArray(vm.columns)) {
        var tempColumns = {};
        for (var i = vm.columns.length - 1; i >= 0; i--) {
          tempColumns[vm.columns[i]] = true;
        }
        vm.columns = tempColumns;
      } else {
        vm.columns = defaultColumns;
      }

      sortOrder(vm.predicate, vm.reverse);

      $scope.$watch('vm.courses', function(){
        angular.forEach(vm.courses, function(value) {
          var score = getScoreVal(value);

          angular.extend(value, {
            statusNameSorting: getStatus(value),
            // It can be used only for sort
            scorePoints: score[0],
            scorePercentage: score[1],
            hoursSorting: getHoursVal(value)
          });
        });
      });

      $scope.$watch(function () {
        return $location.search();
      }, function (value, oldValue) {
        // url didn't change or it was changed by the internal function 'sortOrder'
        if ((value === oldValue) || (vm.predicate === value.filter && vm.reverse === (value.dir === 'desc'))) {
          return;
        }

        vm.predicate = value.filter || vm.defaultSorting.field;
        vm.reverse = (value.dir || vm.defaultSorting.dir) === 'desc';
      }, true);

      function getHoursVal(node){
        if(node.standardHours){
          return +node.standardHours;
        } else if(!node.standardHours && node.course && node.course.length){
          return node.course.length;
        }
      }

      function getScoreVal(node) {
        var scorePercentage, scorePoints;

        if(!node.type || node.type === 'course') {
          if(vm.actual === 'last') {
            scorePoints = node.lastRegistration ? node.lastRegistration.courseGradePoints : null;
            scorePercentage = node.lastRegistration ? node.lastRegistration.courseGradePercentage : null;
          } else {
            scorePoints = node.lastCompletedRegistration ? node.lastCompletedRegistration.courseGradePoints : node.highestScorePoints;
            scorePercentage = node.lastCompletedRegistration ? node.lastCompletedRegistration.courseGradePercentage : node.highestScorePercentage;
          }
        }

        if(typeof scorePoints === 'number') {
          scorePoints = +scorePoints.toFixed();
        } else if(typeof scorePercentage === 'number') {
          scorePercentage = +scorePercentage.toFixed();
        }

        // It can be used only for sort
        return [
          scorePoints,
          scorePercentage
        ];
      }

      function getStatus(node) {
        var status;
        if (!node.type || node.type === 'course') {
          if (vm.actual === 'last') {
            status = node.lastRegistration ? node.lastRegistration.statusId : null;
          } else {
            status = node.lastCompletedRegistration ? node.lastCompletedRegistration.statusId : node.statusId;
          }
          status = status || courseRegistrationStatus.notStarted;
        }

        if (node.type === 'externalRecord'){
          status = node.statusId;
        }

        if (!status){
          //Used for sorting
          return 'Incomplete';
        }

        return courseRegistrationStatus.names[status];
      }
    }

    function sortOrder(predicate, reverse) {
      if (vm.sortingOnServer && _.isEqual(predicate, ['scorePoints', 'scorePercentage'])) {
        predicate = 'scoreSorting';
      }

      vm.reverse = (angular.isDefined(reverse)) ? reverse : _.isEqual(vm.predicate, predicate) ? !vm.reverse: false;
      vm.predicate = predicate;

      var searchQuery = $location.search();
      angular.extend(searchQuery, {filter: vm.predicate, dir: vm.reverse ? 'desc' : 'asc'});
      $location.search(searchQuery);
    }

    function isCurrentSortPredicate(predicate) {
      return _.isEqual(vm.predicate, predicate);
    }
  }
})();



