(function () {

  angular.module('kmi.lms.core')
    .component('bmsPromptView', {
      controller: PromptViewController,
      controllerAs: 'vm',
      template: require('ajs/custom_modules/bms/app/prompt-view.html').default
    });

  function PromptViewController($state, siteSettingService){
    var vm = this;
    const statesForExtraBanner = ['prompt.login', 'prompt.userRegistration'];

    vm.currentDate = new Date();
    vm.siteSettings = siteSettingService.getSiteSetting();

    vm.$onInit = onInit;

    function onInit(){
      vm.extraBannerEnabled = vm.siteSettings.basePath === 'dcjs' && statesForExtraBanner.includes($state.current.name);
    }
  }

})();
