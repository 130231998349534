(function () {

  angular
    .module('kmi.lms.bms.user')
    .component('bmsUserQRCodeDialog', {
      template: require('ajs/custom_modules/bms/user/modal/user-qr-code-dialog.html').default,
      controller: UserQRCodeDialogController,
      controllerAs: 'vm',
      bindings: {
        resolve: '<',
        modalInstance: '='
      }
    });

  function UserQRCodeDialogController() {
    const vm = this;

    vm.$onInit = onInit;
    vm.cancel = cancel;

    function onInit() {
      vm.user = vm.resolve.user;
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
